import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout4';
import SEO from '../components/seo';
import { container, header, movie, moviebutton, main, sidebar, box11, box12, box21, box22, box31, box32, footer } from './fsaas.module.css';
import { StaticImage } from "gatsby-plugin-image";

const App = () => {
  return (
    <Layout>
      <SEO
        title="ジオグラフとは"
        description="地域見える化GIS「ジオグラフ」の使い方を解説しているページです。"
      />

<div id="t"></div>

<div className={container}>

    <header className={header}>
    <h1>ジオグラフとは</h1>
    </header>

<movie className={movie}>
<h2>
    動画による使い方解説
  </h2>
  <a href="" className={moviebutton}>1
  </a>
  <a href="" className={moviebutton}>2
  </a>
  <a href="" className={moviebutton}>3
  </a>
    <h3>
      <span style={{color:"red"}}>Coming Soon!</span>
      </h3>


</movie>


    <main className={main}>

    <StaticImage src="../images/saas/tuto4.png" alt="img" />

    </main>
    <aside className={sidebar}>
      <p>４.ジオグラフでは他にも、２つの地域を比較したり、国土全体で眺めたりもできる、５種類のモードを備えています。<br/>
      この「モード」は、上段のタブで変えられます。<br/>
      はじめは「自分で地域を選ぶ」モードとなっています。<br/>
      気になるタブを開いてみましょう。<br/>
      なお、「典型例とその解説」モードでは、選んだテーマについての解説も読むことができます。</p>
    </aside>


    <box11 className={box11}>
    <StaticImage src="../images/saas/tuto1.png" alt="img" />
    </box11>

    <box12 className={box12}>
    <p>1. ジオグラフでは、左側に凡例、右側に地域の状況を３次元グラフで「見える化」させた地図が配置されています。<br/>
    <br/>【トップページ】でタイトル部分をクリックし、【テーマ選択ページ】で「見える化」させたいテーマを選び終えると、こちらの【プレビューページ】が表示されます。<br/>
    <br/>
    【プレビューページ】では、まず、見たい地域の市町村名もしくは都道府県名を入力しましょう。３次元グラフの描画が始まります。<br/>
    なお、最初は、データ量が小さくなる面積の小さな市町村で、試しに入力してみると良いでしょう。
    <br/><br/></p>
    </box12>

    <box21 className={box21}>
<p>
2. ３次元グラフが描画され始めたら、凡例を確認しましょう。こちらの例では、高さは人口、色は昼夜間人口比となっていることがわかります。<br/><br/>
昼夜間人口比とは、昼間と夜の人口の比率のことで、昼/夜で計算します。すなわち大きいほど昼間の人口が多いことを示します。<br/>
<br/>
高い＝人口が多い<br/>
低い＝人口が少ない<br/>
赤い＝昼間の人口が夜より多い<br/>
青い＝昼間の人口が夜より少ない<br/>
<br/>
最初のうちは、凡例と地図を交互に見ながら、読み取り方に慣れていきましょう。
</p>
</box21>

    <box22 className={box22}>
    <StaticImage src="../images/saas/tuto5.png" alt="img" />
    </box22>

    <box31 className={box31}>
<p>
３. 地図と３次元グラフを確認しましょう。
<br/>
ジオグラフでは、地図上に人口や産業などのグラフが３次元で可視化されています。
<br/><br/>
以下の操作が可能です。
<br/>
４ケタの数字：数字の年次の３次元グラフを表示します。<br/>
はじめに戻る：初期視点に戻します。<br/>
回転させる：中心を固定して回転します。<br/>
場所を確認する：視点の直下の場所をGoogleMapで開きます。<br/>
背景だけを表示：３次元グラフを消します。<br/>
GoogleEarthで表示：描画させた３次元グラフをKMLファイルとしてダウンロードできます。KMLファイルは、GoogleEarthPro上で呼び出すことができます。
<br/><br/>
まずは、「回転させる」を押してグラフを読み取り、「はじめに戻る」を押して元の位置に戻ってみましょう。<br/>
地図は、画面を直接タッチして自由に動かすこともできます。プレビュー画面内の右端のコントロールパネルでも操作できます。
</p>
</box31>

    <box32 className={box32}>
    <StaticImage src="../images/saas/tuto3.png" alt="img" />
    </box32>

    <footer className={footer}>
    さあ、それではジオグラフを使って地域を探究していきましょう。<br/>
    以下は、代表的なテーマへのリンクとなります。<br/>
<br/>
    <table>
	<thead>
		<tr>
			<th>テーマ</th>
			<th>高さ</th>
			<th>色</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td>
        <Link to="/11-1/a/#t">人口分布の変化</Link>
      </td>
			<td>人口総数</td>
			<td>人口密度</td>
		</tr>

		<tr>
			<td>
        <Link to="/11-5/a/#t">シャッター通りと郊外型ショッピングセンター</Link>
      </td>
			<td>小売業年間販売額</td>
			<td>小売業年間販売額伸び率</td>
		</tr>

		<tr>
			<td>
      <Link to="/11-3/a/#t">コンパクトシティ</Link>
      </td>
			<td>人口総数</td>
			<td>公共交通利用圏</td>
		</tr>

		<tr>
			<td>
      <Link to="/11-2/a/#t">人口の都心回帰</Link>
      </td>
			<td>人口総数</td>
			<td>昼夜間人口比</td>
		</tr>

		<tr>
			<td>
      <Link to="/10-1/a/#t">進む国際化</Link>
      </td>
			<td>外国人人口総数</td>
			<td>人口密度</td>
		</tr>

		<tr>
			<td>
      <Link to="/11-4/a/#t">鉄道・車と商業</Link>
      </td>
			<td>小売業年間販売額</td>
			<td>公共交通利用圏</td>
		</tr>

		<tr>
			<td>
      <Link to="/3-1/a/#t">ニュータウンの高齢化</Link>
      </td>
			<td>老年人口率</td>
			<td>老年人口密度</td>
		</tr>

		<tr>
			<td>
      <Link to="/5-1/a/#t">働く人々の男女比</Link>
      </td>
			<td>全産業従業者数</td>
			<td>全産業従業者数男女比</td>
		</tr>

	</tbody>
</table>

  </footer>
</div>

    </Layout>
  );
};

export default App;