// extracted by mini-css-extract-plugin
export const container = "fsaas-module--container--1vQiF";
export const movie = "fsaas-module--movie--3voPa";
export const moviebutton = "fsaas-module--moviebutton--3kR1t";
export const header = "fsaas-module--header--1F9sq";
export const slideInRight = "fsaas-module--slideInRight--EX7wy";
export const keyvisual = "fsaas-module--keyvisual--3QTQk";
export const main = "fsaas-module--main--1lN5q";
export const sidebar = "fsaas-module--sidebar--1ablU";
export const footer = "fsaas-module--footer--3tTsq";
export const box11 = "fsaas-module--box11--3Qqgd";
export const SlideIn = "fsaas-module--SlideIn--1-g1G";
export const box12 = "fsaas-module--box12--3JiGe";
export const box21 = "fsaas-module--box21--29UQe";
export const box22 = "fsaas-module--box22--3kAim";
export const box32 = "fsaas-module--box32--1s50c";
export const box31 = "fsaas-module--box31--CU8Js";